/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { prepareUrl } from "config/constants";
/****************** DEPENDENCIES : COMPONENTS ******************/
import { Col, Row, Typography, Image } from "antd";

import { BookWish, BookedInfo, Can } from "components/shared";
import logo from "../../../../assets/logo.svg";
/****************** STYLING ******************/
import "./WishDetailContent.less";
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { subject } from "@casl/ability";
import { WishPublic, WishWithBookings, BirthRegistry } from "bbb-api";
import _ from "lodash";

/****************** RENDERING (export) ******************/
type InputProps = {
  birthRegistry?: BirthRegistry;
  wishWithBookings?: WishWithBookings;
  wishPublic?: WishPublic;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class WishDetailContent extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */
  /* Handlers methods */
  /* Render methods */
  render() {
    const { wishWithBookings, wishPublic, birthRegistry } = this.props;
    const wish = wishWithBookings ? wishWithBookings : wishPublic ? wishPublic : undefined;
    if (wish && birthRegistry) {
      return (
        <Row className="wishDetailContent" gutter={[20, 20]} align="top" justify="space-between">
          <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10} className="cover">
            <Row justify="center">
              <Image
                src={wish.img_path ? prepareUrl(wish.img_path) : logo}
                className="logo"
                alt="logo"
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
            <Row className="description">
              {wish.description ? (
                <Typography.Text>{wish.description}</Typography.Text>
              ) : (
                <FormattedMessage id="wishDetail.empty"></FormattedMessage>
              )}
            </Row>
            {_.size(wish?.bookings) < (wishPublic?.quantity ?? 1) ? (
              <Can not I="manage" this={subject("BirthRegistry", birthRegistry)}>
                <Row justify="center">
                  <BookWish wishPublic={wishPublic} birthRegistry={birthRegistry}></BookWish>
                </Row>
              </Can>
            ) : undefined}
            <BookedInfo
              wish={wish}
              birthRegistry={birthRegistry}
              bookings={wishWithBookings?.bookings}
              bookingsMinimal={wishPublic?.bookings}
            ></BookedInfo>
          </Col>
        </Row>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(WishDetailContent);
