import * as _ from "lodash";
import globalAxios from 'axios';
import { notification } from "antd";
import {
  Configuration,
  LoginApi,
  UserApi,
  BabyApi,
  BirthregistryApi,
  WishApi,
  ShortlinkApi,
  CategoryApi,
  S3Api,
  BookingApi,
  PasswordResetApi,
  ScraperApi,
  EmailApi,
  HealthcheckApi,
} from "bbb-api";
import { REACT_APP_BBB_API_URL } from "./constants";
import { authenticate } from "store/actions";
import { store } from "index";
import * as log from "loglevel";


globalAxios.interceptors.response.use(
  response => response,
  error => {
    if (!error.response || error.response.status === 403) {
      log.info("Token expired, error 403");
      notification["error"]({
        message: "Session expirée",
        description: "Reconnectez-vous",
        duration: undefined
      });
      store.dispatch(authenticate(false));
      localStorage.removeItem("bbb");
    }
    throw error;
  }
)

export const configurationFactory = (): Configuration => {
  const jwt = localStorage.getItem("bbb");
  const configuration = new Configuration({
    basePath: REACT_APP_BBB_API_URL,
    accessToken: !_.isNil(jwt) ? jwt : undefined
  });
  return configuration;
};

export const loginApiFactory = (defaultConfig?: Configuration): LoginApi => {
  if (defaultConfig) {
    return new LoginApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new LoginApi(config, config.basePath, globalAxios);
  }
};

export const passwordApiFactory = (defaultConfig?: Configuration): PasswordResetApi => {
  if (defaultConfig) {
    return new PasswordResetApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new PasswordResetApi(config, config.basePath, globalAxios);
  }
};

export const userApiFactory = (defaultConfig?: Configuration): UserApi => {
  if (defaultConfig) {
    return new UserApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new UserApi(config, config.basePath, globalAxios);
  }
};

export const babyApiFactory = (defaultConfig?: Configuration): BabyApi => {
  if (defaultConfig) {
    return new BabyApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new BabyApi(config, config.basePath, globalAxios);
  }
};

export const birthRegistryApiFactory = (defaultConfig?: Configuration): BirthregistryApi => {
  if (defaultConfig) {
    return new BirthregistryApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new BirthregistryApi(config, config.basePath, globalAxios);
  }
};

export const wishApiFactory = (defaultConfig?: Configuration): WishApi => {
  if (defaultConfig) {
    return new WishApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new WishApi(config, config.basePath, globalAxios);
  }
};

export const categoryApiFactory = (defaultConfig?: Configuration): CategoryApi => {
  if (defaultConfig) {
    return new CategoryApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new CategoryApi(config, config.basePath, globalAxios);
  }
};

export const shortLinkApiFactory = (defaultConfig?: Configuration): ShortlinkApi => {
  if (defaultConfig) {
    return new ShortlinkApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new ShortlinkApi(config, config.basePath, globalAxios);
  }
};

export const bookingApiFactory = (defaultConfig?: Configuration): BookingApi => {
  if (defaultConfig) {
    return new BookingApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new BookingApi(config, config.basePath, globalAxios);
  }
};

export const s3ApiFactory = (defaultConfig?: Configuration): S3Api => {
  if (defaultConfig) {
    return new S3Api(defaultConfig);
  } else {
    const config = configurationFactory();
    return new S3Api(config, config.basePath, globalAxios);
  }
};

export const scraperApiFactory = (defaultConfig?: Configuration): ScraperApi => {
  if (defaultConfig) {
    return new ScraperApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new ScraperApi(config, config.basePath, globalAxios);
  }
};

export const emailApiFactory = (defaultConfig?: Configuration): EmailApi => {
  if (defaultConfig) {
    return new EmailApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new EmailApi(config, config.basePath, globalAxios);
  }
};

export const healthcheckApiFactory = (defaultConfig?: Configuration): HealthcheckApi => {
  if (defaultConfig) {
    return new HealthcheckApi(defaultConfig);
  } else {
    const config = configurationFactory();
    return new HealthcheckApi(config, config.basePath, globalAxios);
  }
};
