/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Row, Col, Menu, Badge, Image } from "antd";

import CookieConsent from "react-cookie-consent"; import SubMenu from "antd/lib/menu/SubMenu";
import { Me } from "components/header/me";
import { ThemeSelector } from "./theme-selector";
import { LangSelector } from "./lang-selector";
import { Loading } from "./loader";
import { Notifier } from "./notifier";
import logo from "../../assets/logo.svg";

/****************** STYLING ******************/
import "./Header.less";

/****************** DEFINITIONS ******************/
import { Store, Theme } from "store/reducers";
import { MenuInfo } from "rc-menu/lib/interface";
import { User } from "bbb-api/dist/models";
import { ControlOutlined } from "@ant-design/icons";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/

type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {
  langSelectorFeature: boolean;
  authenticated: boolean;
  theme: Theme;
  currentUser?: User;
  basketSize: number;
};
type MapDispatchToProps = {};
type State = { currentkey: string };

class Header extends React.Component<Props, State> {
  state: State = { currentkey: "home" };

  /* LifeCycle Methods */

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `header.${id}` }, param);
  };

  handleClickMenu = (e: MenuInfo) => {
    log.info("handleClickMenu ", e);
    this.setState({ currentkey: e.key.toString() });
  };

  /* Render methods */
  renderAdminMenu() {
    const { currentkey } = this.state;
    if (this.props.currentUser?.is_superuser) {
      return (
        <Menu
          theme={this.props.theme}
          onClick={this.handleClickMenu}
          selectedKeys={[currentkey]}
          mode="horizontal"
        >
          <SubMenu title={<ControlOutlined />}>
            <Menu.ItemGroup title={this.getTraduction("admin.title")}>
              <Menu.Item key="admin:0">
                <ThemeSelector></ThemeSelector>
              </Menu.Item>
              <Menu.Item key="admin:1">
                <FormattedMessage id="header.admin.birthRegistries" />
                <Link to={BBBRoutes.ADMIN_BIRTHREGISTRIES.path} />
              </Menu.Item>
              <Menu.Item key="admin:2">
                <FormattedMessage id="header.admin.babies" />
                <Link to={BBBRoutes.ADMIN_BABIES.path} />
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
        </Menu>
      );
    }
  }

  renderUserMenu() {
    if (this.props.authenticated) {
      return (
        <Link to={BBBRoutes.USER_BIRTHREGISTRIES.path}>
          <FormattedMessage id="header.myBirthRegistries" />
        </Link>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="App-header">
          <CookieConsent
            location="top"
            buttonText="J'ai compris"
            cookieName="bbb-cookie"
            disableButtonStyles
            buttonClasses={"ant-btn ant-btn-primary ant-btn-lg secondary mr10"}
            expires={150}
          >
            <FormattedMessage id="header.cookie" />
          </CookieConsent>
          <Row align="middle" justify="space-between" gutter={[10, 10]} wrap={true}>
            <Col xs={7} sm={13} md={9} lg={9} xl={7} xxl={5}>
              <Link to={BBBRoutes.HOME.path}>
                <Image src={logo} className="app-logo" alt="logo" preview={false} />
              </Link>
            </Col>
            <Col xs={4} sm={11} md={4} lg={5} xl={3} xxl={1}>
              <Loading />
            </Col>
            <Col xs={13} sm={24} md={9} lg={8} xl={8} xxl={4} className="personalMenu">
              <Row justify="end" wrap={false}>
                <Col xs={0} sm={13} md={0} lg={0} xl={0} xxl={0}>
                  <div></div>
                </Col>
                <Col>
                  <Row justify="space-between" wrap={false}>
                    {this.renderAdminMenu()}
                    {this.props.langSelectorFeature && <LangSelector />}
                    <Me></Me>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={14} className="subPages">
              <Row wrap={true} justify="space-between">
                <Col xs={12} sm={12} md={6} lg={6} xl={7} xxl={5}>
                  {this.renderUserMenu()}
                </Col>
                <Col xs={12} sm={12} md={9} lg={10} xl={9} xxl={10}>
                  <a href={"https://blog.bienvenuebebe.com/"} target="_blank" rel="noreferrer">
                    <FormattedMessage id="header.blog" />
                  </a>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                  <Link to={BBBRoutes.HELP.path}>
                    <FormattedMessage id="header.help" />
                  </Link>
                </Col>
                <Col xs={12} sm={12} md={6} lg={5} xl={5} xxl={6}>
                  <Link to={BBBRoutes.BASKET.path}>
                    <Badge count={this.props.basketSize} showZero={false}>
                      <FormattedMessage id="header.basket" />
                    </Badge>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Notifier />
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    langSelectorFeature: state.config.features.langSelectorFeature,
    authenticated: state.user.authenticated,
    currentUser: state.user.currentUser,
    theme: state.config.theme,
    basketSize: state.basket.basketSize,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}

export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
